//
//
//
//
//
//
//

import FilterMore
    from "@/components/pages/store/StoreFilter/Components/FilterBar/Components/FilterControl/Components/FilterMore";
import FilterSort
    from "@/components/pages/store/StoreFilter/Components/FilterBar/Components/FilterControl/Components/FiltersSort";
export default {
    name: "FilterControl",
    components: {FilterSort, FilterMore},
    props: {
        showMore: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toggleOpened() {
            this.$emit('toggleOpened')
        }
    }
}
