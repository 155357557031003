//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import close from '~/assets/img/icons/closeSmall.svg?raw'

export default {
    name: "FilterApplied",
    computed: {
        ...mapGetters({
            applied: 'catalogStore/getAppliedFilters',
            filters: 'catalogStore/getFilters',
            attributesFilterSelectionHistory: 'catalogStore/attributesFilterSelectionHistory',
            showClearAllLink: 'catalogStore/showClearAllLink',
            clearLinkUrl: 'catalogStore/clearLinkUrl',
        }),
        genderFilter() {
            return this.applied.filter(i => i.on === 'gender');
        },
    },
    data() {
        return {
            closeIcon: close
        }
    },
    methods: {
        removeHistoryItem(filter) {
            this.$store.commit(`catalogStore/${types.REMOVED_ALL_OPENED_FILTER}`)
            this.$store.commit(`catalogStore/${types.REMOVE_FILTER_HISTORY_ITEM}`, {
                id: filter.id,
            })
        },
        clearAll() {
            this.$store.commit(`catalogStore/${types.REMOVED_ALL_OPENED_FILTER}`)
            this.$store.commit(`catalogStore/${types.CLEAR_FILTER_HISTORY}`)
        }
    }

}
