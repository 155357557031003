//
//
//
//
//
//
//

export default {
    name: "FilterMore",
    props: {
        showMore: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        btnText() {
            return this.showMore ? this.$t('store.filter.fewerFilters') : this.$t('store.filter.moreFilters');
        }
    },
    methods: {
        toggleOpened() {
            this.$emit('toggleOpened')
        }
    }
}
