//
//
//
//
//
//
//
//
//

import FiltersList from "@/components/pages/store/StoreFilter/Components/FilterBar/Components/FiltersList/index";
import FilterControl from "@/components/pages/store/StoreFilter/Components/FilterBar/Components/FilterControl/index";
import { mapGetters } from "vuex"
export default {
    name: "FilterBar",
    components: {FilterControl, FiltersList},
    data() {
        return {
            sticky: false
        }
    },
    computed: {
        ...mapGetters({
            allFiltersShowed: 'catalogStore/getAllFiltersShowed',
        }),
    },
    methods: {
        toggleOpened() {
            this.$store.commit(`catalogStore/${types.CHANGE_ALL_FILTERS_SHOWED_STATE}`, !this.allFiltersShowed)
        },
        stickyScroll() {
            let scroll = window.scrollY,
                header = document.getElementsByClassName('header')[0].offsetHeight,
                headerContainer = document.getElementsByClassName('header__static-container')[0];

            let headerSticky = headerContainer.classList.contains('hide-header') ? 0 : headerContainer.offsetHeight;
            this.sticky = scroll >= (this.$refs.bar.offsetTop - 20 - headerSticky);
            this.$refs.panel.style.marginTop = this.sticky ? headerSticky+'px' : 0;
            this.$refs.bar.style.height = this.sticky ? this.$refs.panel.offsetHeight+'px' : 'auto';
        }
    },
    mounted(){
        window.addEventListener('scroll', this.stickyScroll);
    },
    beforeDestroy(){
        window.removeEventListener('scroll', this.stickyScroll);
    },
}
