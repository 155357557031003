//
//
//
//
//
//
//
//

import FilterBar from "@/components/pages/store/StoreFilter/Components/FilterBar/index";
import FilterApplied from "@/components/pages/store/StoreFilter/Components/FilterApplied/index";
import FilterMobile from "@/components/pages/store/StoreFilter/Components/FilterMobile/index";
export default {
name: "StoreFilter",
    components: {FilterMobile, FilterApplied, FilterBar}
}
